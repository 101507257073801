import React from "react";
import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import {getConfig} from "../../i18n";
import {CONFIG, ROLES} from "../../../_ae/config";


const config = getConfig();


const colors = {
  primary: "#f9a563",
  secondary:"#3a2588",
  danger:"#F64E60",
}



export function MaterialThemeProvider(props) {
  const { children } = props;
  const theme = createMuiTheme(
    /**
     * @see https://material-ui.com/customization/themes/#theme-configuration-variables
     */

    {
      //.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline
      overrides: {
        // shape: { borderRadius: "1.47rem" },
        // MuiPaper:{ root:{ borderRadius:"1.47rem !important"}},
        // MuiInputBase: {  root:{ borderRadius:"1.47rem !important", borderColor: 'green !important' },
        //   // disabled:{backgroundColor:"black !important"}
        // },
        // MuiFilledInput: { root:{ borderRadius:"1.47rem" }},
        MuiOutlinedInput: {
          notchedOutline: { borderColor: '#E4E6EF !important'/*, borderWidth:"2px !important"*/},
          // disabled:{backgroundColor:"black !important"}
        },
        MuiFormLabel:{ root:{color:"#B5B5C3 !important"}}
      },
      direction:  config.rtl ? "rtl" : "ltr",
      typography: {
        fontFamily: ["Poppins"].join(",")
      },

      //todo use template's config values
      palette: {
        primary: {
          // light: will be calculated from palette.primary.main,
          main: colors.primary
          // dark: will be calculated from palette.primary.main,
          // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
        },
        secondary: {
          // light: will be calculated from palette.primary.main,
          main: colors.secondary
          // dark: will be calculated from palette.primary.main,
          // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
        },
        error: {
          // light: will be calculated from palette.primary.main,
          main: colors.danger,
          // dark: will be calculated from palette.primary.main,
          // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
        }
      },

      /**
       * @see https://material-ui.com/customization/globals/#default-props
       */
      props: {
        // Name of the component ⚛️
        MuiButtonBase: {
          // The properties to apply
          disableRipple: false // No more ripple, on the whole application 💣!
        },

        // Set default elevation to 1 for popovers.
        MuiPopover: {
          elevation: 1
        }
      }
    }
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
