import axios from "axios";
import {axiosFormDataConfigs, getFormData} from "../../_ae/helpers/AxiosHelpers";
import {toAbsoluteApiUrl} from "../../_metronic/_helpers";
import set from "lodash/set";

export const API_URI = toAbsoluteApiUrl("/users");

export const normalize = _entity => {
  const {
    id, username, firstName, lastName, email, password, role, school,
    attributes
  } = _entity;

  let result = {
    id, username, firstName, lastName, email, password,
    attributes
  };
  if (role) {
    set(result, 'role.id', role.id)
  }
  if (school) {
    set(result, 'school.id', school.id)
  }

  return result
}

export const serializeParams = metadata => {
  const {search, role, school} = metadata.filters;

  return {
    ...metadata,
    filters: {
      ...metadata.filters,
      search: search ? search : undefined,
      role: role ? {id: role.id} : undefined,
      school: school ? {id: school.id} : undefined,
    }
  }
}


export const all = (metadata) => axios.get(API_URI, { params: { meta: serializeParams(metadata) }});
export const find = (id, metadata) => axios.get(`${API_URI}/${id}`, { params: { meta: metadata }});
export const destroy = id => axios.delete(`${API_URI}/${id}`);
export const create = (entity, files) => axios.post(API_URI, getFormData(normalize(entity), files), axiosFormDataConfigs );
export const update = (entity, files) => axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(normalize(entity), files), axiosFormDataConfigs);


