import axios from "axios";
import {axiosFormDataConfigs, getFormData, serializeParams} from "../../_ae/helpers/AxiosHelpers";
import {toAbsoluteApiUrl} from "../../_metronic/_helpers";
import set from "lodash/set";

export const API_URI = toAbsoluteApiUrl("/students");

export const normalize = ({fileName, ...entity}) => {
  if (entity.parent) {
    entity.parent = {id: entity.parent.id}
  }
  if (entity.team) {
    entity.team = {id: entity.team.id}
  }

  return entity
}

export const customSerializeParams = (metadata) => {
  const {search, parent, team} = metadata.filters;

  return {
    ...metadata,
    filters: {
      ...metadata.filters,
      search: search ? search : undefined,
      parent: parent ? {id: parent.id} : undefined,
      team: team ? {id: team.id} : undefined,
    }
  }
}

export const all = (metadata) =>  {
  return axios.get(API_URI, { params: { meta: customSerializeParams(metadata) }});
}
export const find = id => {
  return axios.get(`${API_URI}/${id}`);
}
export const destroy = id => {
  return axios.delete(`${API_URI}/${id}`);
}
export const create = (entity, files) => {
  return axios.post(API_URI, getFormData(normalize(entity), files), axiosFormDataConfigs );
}
export const update = (entity, files) => {
  return axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(normalize(entity), files), axiosFormDataConfigs);
}


