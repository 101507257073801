import { _reducer as rolesReducer} from './slices/list'
import { _reducer as rolesDeleteReducer} from './slices/delete'
import { _reducer as rolesEditReducer} from './slices/edit'
import { _reducer as rolesForSelectReducer} from './slices/listForSelect'

export const rolesReducers = {
  ...rolesReducer,
  ...rolesDeleteReducer,
  ...rolesEditReducer,
  ...rolesForSelectReducer
}

export {
  fetchDispatcher as fetchRoles,
  useSelector as useRolesList
} from './slices/list'

export {
  useSelector as useRolesEdit,
  fetchDispatcher as fetchRoleForEdit,
  resetDispatcher as resetRoleForEdit,
  saveDispatcher as saveRole
} from './slices/edit'

export {
  useSelector as useRolesDelete,
  fetchDispatcher as fetchRoleForDelete,
  deleteDispatcher as deleteRole
} from './slices/delete'

export {
  useSelector as useRolesForSelect,
  fetchDispatcher as fetchRolesForSelect,
  actions as rolesForSelectActions
} from './slices/listForSelect'
