import React, {useMemo} from "react";
import { Link } from "react-router-dom";
import {toAbsoluteUrl, toEntityFileNameUrl} from "../../../_helpers";
import {getConfig} from "../../../i18n";
import {ROLES} from "../../../../_ae/config";
import {useAuthState} from "../../../../redux/auth";
import {MODULES} from "../../../../_ae/helpers/RoutingHelpers";



export function Brand() {
  const {user} = useAuthState()

  const url = useMemo(()=>{
    return user.school ?
      toEntityFileNameUrl(user.school, MODULES.SCHOOLS):
      toAbsoluteUrl(`/media/logos/logo-letter-primary.png`)
  }, [user.school])


  return (
    <>
      {/* begin::Brand */}
      <div
        className={`aside-brand d-flex flex-column align-items-center flex-column-auto py-5 py-lg-12 s`}
      >
        {/* begin::Logo */}
        <Link to="/" className="brand-logo">
          <img
            alt="logo"
            src={url}
            className={`max-h-40px`}
          />
        </Link>
        {/* end::Logo */}
      </div>
      {/* end::Brand */}
    </>
  );
}
