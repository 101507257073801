import { _reducer as usersReducer} from './slices/list'
import { _reducer as usersDeleteReducer} from './slices/delete'
import { _reducer as usersEditReducer} from './slices/edit'
import { _reducer as driversForSelectReducers} from './slices/driversForSelect'
import { _reducer as parentsForSelectReducers} from './slices/parentsForSelect'

export const usersReducers = {
  ...usersReducer,
  ...usersDeleteReducer,
  ...usersEditReducer,
  ...driversForSelectReducers,
  ...parentsForSelectReducers
}

export {
  fetchDispatcher as fetchUsers,
  useSelector as useUsersListState,
  actions as usersListActions
} from './slices/list'

export {
  useSelector as useUsersEditState,
  fetchDispatcher as fetchUserForEdit,
  resetDispatcher as resetUserForEdit,
  saveDispatcher as saveUser
} from './slices/edit'

export {
  useSelector as useUsersDeleteState,
  fetchDispatcher as fetchUserForDelete,
  deleteDispatcher as deleteUser
} from './slices/delete'

export {
  fetchDispatcher as fetchDriversForSelect,
  useSelector as useDriversForSelect,
  actions as driversForSelectActions
} from './slices/driversForSelect'

export {
  fetchDispatcher as fetchParentsForSelect,
  useSelector as useParentsForSelect,
  actions as ParentsForSelectActions
} from './slices/parentsForSelect'