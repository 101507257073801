import React from "react";
import {
  AEAutocompleteField,
  AEField, AEFileField
} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {MODULES} from "../../../../../_ae/helpers/RoutingHelpers";
import {FieldArray} from "formik";
import {FormattedMessage, useIntl} from "react-intl";
import {AEButton} from "../../../../../_ae/components/buttons";
import {defaultAttribute} from "../../../../../redux/schools/slices/edit";
import {Card, CardBody} from "../../../../../_metronic/_partials/controls";
import {AEIcon, ICONS} from "../../../../../_ae/components/svg";
import {getAttributeKeys} from "../../../Users/components/Attribute";

export const Form = ({formik: {values, handleSubmit}, btnRef}) => {
  const {formatMessage} = useIntl();
  return (
    <div className="form form-label-right">
      <div className={'row'}>
        <div className={'col-lg-4 form-group max-h-200px'}>
          <AEFileField
            name="fileName"
            label={'IMAGE'}
            preview
            previewPath={`/${MODULES.SCHOOLS}`}
          />
        </div>
        <div className={'col-lg-8'}>
          <div className="row">
            <div className={'form-group col-md'}>
              <AEField
                name="nameFr"
                label={'FIRST_NAME'}
              />
            </div>
          </div>
          <div className="row">
            <div className={'form-group col-md'}>
              <AEField
                name="email"
                label={'EMAIL'}
                type={'email'}
              />
            </div>
          </div>
          <div className="row">
            <div className={'form-group col-md'}>
              <AEField
                name="lat"
                label={'LAT'}
                // type={'number'}
              />
            </div>
            <div className={'form-group col-md'}>
              <AEField
                name="lng"
                label={'LNG'}
                // type={'number'}
              />
            </div>
          </div>
          <div className="row">
            <div className={'form-group col-md'}>
              <AEField
                name="address"
                label={'ADDRESS'}
                type={'textarea'}
                rows={2}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <FieldArray
          name="attributes"
          render={(arrayHelpers) => (
            <div>
              <div className="d-flex justify-content-between gutter-b">
                <div className="font-weight-bold font-size-h3 pl-4">
                  <FormattedMessage id={'ATTRIBUTES'} />
                </div>
                <div>
                  <AEButton
                    size={'sm'}
                    onClick={()=>{
                      arrayHelpers.push(defaultAttribute)
                    }}
                  >
                    <FormattedMessage id={'ADD_ATTRIBUTE'} />
                  </AEButton>
                </div>
              </div>
              <div>
                {
                  values.attributes.map((attribute, i)=>(
                    <Card key={i} className="card-border">
                      <CardBody className="position-relative">
                        <div className={'row'}>
                          <div className={'-form-group col-md-6'}>
                            <AEAutocompleteField
                              name={`attributes.${i}.attKey`}
                              label={'TYPE'}
                              options={getAttributeKeys()}
                              getOptionLabel={key=>formatMessage({id: key ||'aaa'})}
                            />
                          </div>
                          <div className={'-form-group col-md-5'}>
                            <AEField
                              name={`attributes.${i}.attValue`}
                              label={'VALUE'}
                            />
                          </div>
                        </div>
                        <AEButton
                          variant={'danger'}
                          icon
                          shadow
                          size={'sm'}
                          className='position-absolute top-0 right-0'
                          style={{transform: 'translate(50%, 50%)'}}
                          onClick={()=>{
                            arrayHelpers.remove(i)
                          }}
                        >
                          <AEIcon
                            path={ICONS.DELETE}
                          />
                        </AEButton>
                      </CardBody>
                    </Card>
                  ))
                }
              </div>

            </div>
            )}
          />
      </div>


      <button
        style={{ display: "none" }}
        ref={btnRef}
        onClick={handleSubmit}
      />
    </div>
  );
}

