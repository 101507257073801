import React from "react";
import {
  AEField
} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {SchoolField} from "../../../Schools";
import {localField} from "../../../../../_ae/helpers/UIHelper";
import {AERole} from "../../../../../_ae/components/AERole";
import {CONFIG} from "../../../../../_ae/config";

export const Form = ({formik: {values, handleSubmit, setFieldValue}, btnRef}) => {

  return (
    <div className="form form-label-right">
      <div className="row">
        <div className={'form-group col-md'}>
          <AEField
            name="nameFr"
            label={'FIRST_NAME'}
          />
        </div>
        <AERole roles={[CONFIG.ROLES.SUPER_ADMIN]}>
          <div className={'form-group col-md'}>
            <SchoolField
              name="tenantKey"
              label={'SCHOOL'}
              useTenantKey
              setFieldValue={setFieldValue}
              values={values}
            />
          </div>
        </AERole>
      </div>

      <button
        style={{ display: "none" }}
        ref={btnRef}
        onClick={handleSubmit}
      />
    </div>
  );
}

