import React, {useState} from "react";
import {Card, CardBody} from "../../../../../../_metronic/_partials/controls";
import {Formik} from "formik";
import {Form} from "./Form";
import {AEButton} from "../../../../../../_ae/components/buttons";
import {AEIcon} from "../../../../../../_ae/components/svg";

export const Filter = ({ }) => {
  const [advancedSearch, setAdvancedSearch] = useState(false)

  return (
    <Card>
      <CardBody className="position-relative">
        <AEButton
          variant={'light-primary'}
          icon
          shadow
          size={'sm'}
          className='position-absolute top-0 right-0'
          style={{transform: 'translate(50%, 50%)'}}
          onClick={e=>{
            setAdvancedSearch(!advancedSearch)
          }}
          >
          <AEIcon
            path={`/Navigation/Up-down.svg`}
          />
        </AEButton>
        <Formik
          initialValues={{
            search: '',
            parent: null,
            team: null,
          }}
          onSubmit={() => {}}
          render={formik=>(
            <Form
              formik={formik}
              advancedSearch={advancedSearch}
            />
          )}
        />
      </CardBody>
    </Card>
  )
}