import React, {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {
  Card,
  CardBody, CardHeader, CardHeaderToolbar,
  ModalProgressBar,
} from "../../../../../_metronic/_partials/controls";
import {FormattedMessage} from "react-intl";
import {Formik} from "formik";
import * as Yup from "yup";
import {
  fetchTeamForEdit, resetTeamForEdit,
  saveTeam,
  useTeamsEditState
} from "../../../../../redux/teams";
import {Form} from "./Form";
import {AEButton} from "../../../../../_ae/components/buttons";

export const Edit = ({ history, match: { params: { id }, }}) => {
  const dispatch = useDispatch();
  const { isLoading, data, error } = useTeamsEditState();

  useEffect(() => {
    dispatch(
      id ?
        fetchTeamForEdit(id) :
        resetTeamForEdit()
    );
  }, [id, dispatch]);

  const saveBtnRef = useRef();
  const saveBtnRefClick = () => {
    if (saveBtnRef && saveBtnRef.current) {
      const {current} = saveBtnRef;
      current.click()
    }
  };

  return (
    <Card>
      {isLoading && <ModalProgressBar />}
      <CardHeader className={'d-flex justify-content-end'}>
        <CardHeaderToolbar>
          <AEButton
            variant={'light'}
            onClick={history.goBack}
          >
            <i className="fa fa-arrow-left"/>
            <FormattedMessage id={'BACK'} />
          </AEButton>
          <AEButton
            className="ml-2"
            onClick={saveBtnRefClick}
          >
            <FormattedMessage id={'SAVE'} />
          </AEButton>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Formik
          enableReinitialize
          initialErrors={error}
          validationSchema={
            Yup.object().shape({
              id: Yup.number().nullable(),
              nameFr: Yup.string().required(),
              tenantKey: Yup.string().nullable()
            })
          }
          initialValues={data}
          onSubmit={values=>{
            dispatch(saveTeam(values))
          }}
          render={formik=>(
            <Form
              formik={formik}
              btnRef={saveBtnRef}
            />)}
        />
      </CardBody>

    </Card>
  );
}

