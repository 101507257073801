import React, {Suspense, useEffect} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {ContentRoute, Layout, LayoutSplashScreen} from "../_metronic/layout";
import ErrorsPage from "./pages/ErrorsPages/ErrorsPage";
import {useIntl} from "react-intl";
import {SITE_NAME} from "../_ae/helpers/UIHelper";
import {matchPath} from "react-router";
import {AuthLayout, ForgotPassword, Login, Logout, Registration, ResetPassword} from "./modules/Auth";
import {fetchRoutesForApp, useRoutesForAppState} from "../redux/routes";
import {ICONS} from "../_ae/components/svg";
import {EditPassword, EditProfile} from "./modules/Profile";
import {UsersDelete, UsersEdit, UsersList} from "./modules/Users";
import {RolesDelete, RolesEdit, RolesList} from "./modules/Roles";
import {MODULES, VIEWS} from "../_ae/helpers/RoutingHelpers";
import {TeamsDelete, TeamsEdit, TeamsList} from "./modules/Teams";
import {SchoolsDelete, SchoolsEdit, SchoolsList} from "./modules/Schools";
import {BusesDelete, BusesEdit, BusesHistory, BusesList} from "./modules/Buses";
import {StudentsDelete, StudentsEdit, StudentsList} from "./modules/Students";



export const ROUTES = [
  {
    routeKey: 'AUTH.LOGIN',
    path: `/auth/login`,
    component: Login,
    context: 'auth',
    views: []
  },
  {
    routeKey: 'AUTH.REGISTER',
    path: `/auth/registration`,
    component: Registration,
    context: 'auth',
    views: []
  },
  {
    routeKey: 'AUTH.FORGOT',
    path: `/auth/password/forgot`,
    component: ForgotPassword,
    context: 'auth',
    views: []
  },
  {
    routeKey: 'AUTH.RESET',
    path: `/auth/password/reset/:token`,
    component: ResetPassword,
    context: 'auth',
    views: []
  },
  // Profile
  {
    routeKey: 'USER.PROFILE.EDIT',
    path: `/${MODULES.PROFILE}/edit`,
    svg: ICONS.EDIT,
    component: EditProfile,
    context: MODULES.PROFILE,
    views: []
  },
  {
    routeKey: 'USER.PASSWORD.EDIT',
    path: `/${MODULES.PROFILE}/password/edit`,
    svg: ICONS.EDIT,
    component: EditPassword,
    context: MODULES.PROFILE,
    views: []
  },
  // users
  {
    routeKey: 'USERS.NEW',
    path: `/${MODULES.USERS}/new`,
    svg: ICONS.USERS_NEW,
    component: UsersEdit,
    context: MODULES.USERS,
    views: [VIEWS.ACTION]
  },
  {
    routeKey: 'USERS.EDIT',
    path: `/${MODULES.USERS}/:id/edit`,
    svg: ICONS.EDIT,
    component: UsersEdit,
    context: MODULES.USERS,
    views: []
  },
  {
    routeKey: 'USERS.DELETE',
    path: `/${MODULES.USERS}/:id/delete`,
    svg: ICONS.USERS_DELETE,
    component: UsersDelete,
    context: MODULES.USERS,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'USERS.LIST',
    path: `/${MODULES.USERS}/list`,
    svg: ICONS.USERS,
    component: UsersList,
    context: MODULES.USERS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // Roles
  {
    routeKey: 'ROLES.NEW',
    path: `/${MODULES.ROLES}/new`,
    svg: ICONS.PLUS,
    component: RolesEdit,
    context: MODULES.ROLES,
    views: [VIEWS.ACTION]
  },
  {
    routeKey: 'ROLES.EDIT',
    path: `/${MODULES.ROLES}/:id/edit`,
    svg: ICONS.EDIT,
    component: RolesEdit,
    context: MODULES.ROLES,
    views: []
  },
  {
    routeKey: 'ROLES.DELETE',
    path: `/${MODULES.ROLES}/:id/delete`,
    svg: ICONS.DELETE,
    component: RolesDelete,
    context: MODULES.ROLES,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'ROLES.LIST',
    path: `/${MODULES.ROLES}/list`,
    svg: ICONS.ROLES,
    component: RolesList,
    context: MODULES.ROLES,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // schools
  {
    routeKey: 'SCHOOLS.NEW',
    path: `/${MODULES.SCHOOLS}/new`,
    svg: ICONS.PLUS,
    component: SchoolsEdit,
    context: MODULES.SCHOOLS,
    views: [VIEWS.ACTION]
  },
  {
    routeKey: 'SCHOOLS.EDIT',
    path: `/${MODULES.SCHOOLS}/:id/edit`,
    svg: ICONS.EDIT,
    component: SchoolsEdit,
    context: MODULES.SCHOOLS,
    views: []
  },
  {
    routeKey: 'SCHOOLS.DELETE',
    path: `/${MODULES.SCHOOLS}/:id/delete`,
    svg: ICONS.DELETE,
    component: SchoolsDelete,
    context: MODULES.SCHOOLS,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'SCHOOLS.LIST',
    path: `/${MODULES.SCHOOLS}/list`,
    svg: ICONS.SCHOOLS,
    component: SchoolsList,
    context: MODULES.SCHOOLS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // Students
  {
    routeKey: 'STUDENTS.NEW',
    path: `/${MODULES.STUDENTS}/new`,
    svg: ICONS.PLUS,
    component: StudentsEdit,
    context: MODULES.STUDENTS,
    views: [VIEWS.ACTION]
  },
  {
    routeKey: 'STUDENTS.EDIT',
    path: `/${MODULES.STUDENTS}/:id/edit`,
    svg: ICONS.EDIT,
    component: StudentsEdit,
    context: MODULES.STUDENTS,
    views: []
  },
  {
    routeKey: 'STUDENTS.DELETE',
    path: `/${MODULES.STUDENTS}/:id/delete`,
    svg: ICONS.DELETE,
    component: StudentsDelete,
    context: MODULES.STUDENTS,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'STUDENTS.LIST',
    path: `/${MODULES.STUDENTS}/list`,
    svg: ICONS.STUDENTS,
    component: StudentsList,
    context: MODULES.STUDENTS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // Teams
  {
    routeKey: 'TEAMS.NEW',
    path: `/${MODULES.TEAMS}/new`,
    svg: ICONS.PLUS,
    component: TeamsEdit,
    context: MODULES.TEAMS,
    views: [VIEWS.ACTION]
  },
  {
    routeKey: 'TEAMS.EDIT',
    path: `/${MODULES.TEAMS}/:id/edit`,
    svg: ICONS.EDIT,
    component: TeamsEdit,
    context: MODULES.TEAMS,
    views: []
  },
  {
    routeKey: 'TEAMS.DELETE',
    path: `/${MODULES.TEAMS}/:id/delete`,
    svg: ICONS.DELETE,
    component: TeamsDelete,
    context: MODULES.TEAMS,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'TEAMS.LIST',
    path: `/${MODULES.TEAMS}/list`,
    svg: ICONS.TEAMS,
    component: TeamsList,
    context: MODULES.TEAMS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // Voyages
  // {
  //   routeKey: 'VOYAGES.NEW',
  //   path: `/${MODULES.VOYAGES}/new`,
  //   svg: ICONS.PLUS,
  //   component: RolesEdit,
  //   context: MODULES.VOYAGES,
  //   views: [VIEWS.ACTION]
  // },
  // {
  //   routeKey: 'VOYAGES.EDIT',
  //   path: `/${MODULES.VOYAGES}/:id/edit`,
  //   svg: ICONS.EDIT,
  //   component: RolesEdit,
  //   context: MODULES.VOYAGES,
  //   views: []
  // },
  // {
  //   routeKey: 'VOYAGES.DELETE',
  //   path: `/${MODULES.VOYAGES}/:id/delete`,
  //   svg: ICONS.DELETE,
  //   component: RolesDelete,
  //   context: MODULES.VOYAGES,
  //   views: [VIEWS.DIALOG]
  // },
  // {
  //   routeKey: 'VOYAGES.LIST',
  //   path: `/${MODULES.VOYAGES}/list`,
  //   svg: ICONS.VOYAGES,
  //   component: RolesList,
  //   context: MODULES.VOYAGES,
  //   views: [VIEWS.MENU, VIEWS.ACTION]
  // },
  // Buses
  {
    routeKey: 'BUSES.NEW',
    path: `/${MODULES.BUSES}/new`,
    svg: ICONS.PLUS,
    component: BusesEdit,
    context: MODULES.BUSES,
    views: [VIEWS.ACTION]
  },
  {
    routeKey: 'BUSES.EDIT',
    path: `/${MODULES.BUSES}/:id/edit`,
    svg: ICONS.EDIT,
    component: BusesEdit,
    context: MODULES.BUSES,
    views: []
  },
  {
    routeKey: 'BUSES.DELETE',
    path: `/${MODULES.BUSES}/:id/delete`,
    svg: ICONS.DELETE,
    component: BusesDelete,
    context: MODULES.BUSES,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'BUSES.HISTORY',
    path: `/${MODULES.BUSES}/:id/history`,
    svg: ICONS.CALENDAR,
    component: BusesHistory,
    context: MODULES.BUSES,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'BUSES.LIST',
    path: `/${MODULES.BUSES}/list`,
    svg: ICONS.BUSES,
    component: BusesList,
    context: MODULES.BUSES,
    views: [VIEWS.MENU, VIEWS.ACTION]
  }
]

export function Routes() {
  const dispatch = useDispatch();
  const {isAuthorized, authUser} = useSelector( ({auth}) => ({ authUser: auth.user, isAuthorized: auth.user != null, }), shallowEqual );
  const {data: routes, isLoading} = useRoutesForAppState();
  const {formatMessage} = useIntl();
  const location = useLocation();
  useEffect(()=>{
      const currentRoute = routes.find(r=>matchPath(location.pathname, {path: r.path}))
      let title = SITE_NAME;
      if(currentRoute) {
        title += ` | ${formatMessage({id: currentRoute.routeKey})}`
      }

      document.title = title;
    },[routes, location.pathname])

  useEffect(()=>{
    if (authUser && location.pathname !== '/logout') {
      dispatch(fetchRoutesForApp())
    }
  },[authUser])

  //todo
  if ((isLoading || routes.length === 0) && !!authUser && location.pathname !== '/logout') {
    return <LayoutSplashScreen />
  }

  // console.log(routes, location.pathname)

  const defaultRoute = routes.find(route=>route.routeKey.includes('LIST'));

  return (
    <>
      <Switch>

        <Route path="/error" component={ErrorsPage}/>
        <Route path="/logout" component={Logout}/>

        {
          ! isAuthorized ?
            <AuthLayout >
              <Suspense fallback={<LayoutSplashScreen/>}>
                <Switch>
                  {/*FIX ME*/}
                  <Redirect exact from="/" to="/auth"/>
                  <Redirect exact from="/auth" to="/auth/login"/>
                  {
                    ROUTES
                      .filter(route=>route.context === MODULES.AUTH)
                      .map(route=>(
                        <ContentRoute
                          key={route.routeKey}
                          {...route}
                        />
                      ))
                  }
                </Switch>
              </Suspense>
            </AuthLayout> :
            <Layout>
              <Suspense fallback={<LayoutSplashScreen/>}>
                <Switch>
                  <Redirect exact from="/auth/login" to="/"/>
                  {
                    routes.length > 0 &&
                    <Redirect exact from="/" to={defaultRoute ? defaultRoute.path : routes[0].path}/>
                  }

                  {
                    Object
                      .values(MODULES)
                      .filter(key=>![MODULES.PROFILE].includes(key))
                      .map(moduleName=>(
                        <Redirect
                          key={moduleName}
                          exact
                          from={`/${moduleName}`}
                          to={`/${moduleName}/list`}
                        />
                    ))
                  }

                  {
                    routes
                      .map((route, i)=> {
                        return (
                          <ContentRoute
                            key={route.routeKey}
                            {...route}
                          />
                        )
                      })
                  }
                  <Redirect to="/error"/>
                </Switch>
              </Suspense>
            </Layout>

        }
      </Switch>
    </>
  );
}
