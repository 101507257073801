import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "./auth/authRedux";
import {usersReducers} from "./users";
import {routesReducers} from "./routes";
import {rolesReducers} from "./roles";
import {authReducers} from "./auth";
import {schoolsReducers} from "./schools";
import {busesReducers} from "./buses";
import {studentsReducers} from "./students";
import {teamsReducers} from "./teams";
import {voyagesReducers} from "./voyages";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  ...authReducers,
  ...usersReducers,
  ...rolesReducers,
  ...routesReducers,
  ...schoolsReducers,
  ...busesReducers,
  ...studentsReducers,
  ...teamsReducers,
  ...voyagesReducers,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
