import { _reducer as voyagesReducer} from './slices/list'
import { _reducer as voyagesDeleteReducer} from './slices/delete'
import { _reducer as voyagesEditReducer} from './slices/edit'
import { _reducer as voyagesForSelectReducer} from './slices/listForSelect'
import { _reducer as voyagesForForBusHistoryReducer} from './slices/listForBusHistory'

export const voyagesReducers = {
  ...voyagesReducer,
  ...voyagesDeleteReducer,
  ...voyagesEditReducer,
  ...voyagesForSelectReducer,
  ...voyagesForForBusHistoryReducer
}

export {
  fetchDispatcher as fetchVoyages,
  useSelector as useVoyagesListState,
  actions as voyagesListActions
} from './slices/list'

export {
  useSelector as useVoyagesEditState,
  fetchDispatcher as fetchVoyageForEdit,
  resetDispatcher as resetVoyageForEdit,
  saveDispatcher as saveVoyage
} from './slices/edit'

export {
  useSelector as useVoyagesDeleteState,
  fetchDispatcher as fetchVoyageForDelete,
  deleteDispatcher as deleteVoyage
} from './slices/delete'

export {
  useSelector as useVoyagesForSelect,
  fetchDispatcher as fetchVoyagesForSelect,
  actions as voyagesForSelectActions
} from './slices/listForSelect'

export {
  useSelector as useVoyagesForBusHistory,
  fetchDispatcher as fetchVoyagesForBusHistory,
  actions as voyagesForBusHistoryActions
} from './slices/listForBusHistory'