import axios from "axios";
import {axiosFormDataConfigs, getFormData, serializeParams} from "../../_ae/helpers/AxiosHelpers";
import {toAbsoluteApiUrl} from "../../_metronic/_helpers";

export const API_URI = toAbsoluteApiUrl("/buses");

export const normalize = (entity) => {
  if (entity.driver) {
    entity.driver = {id: entity.driver.id}
  }
  return entity
}

export const customSerializeParams = (metadata) => {
  const {search, driver} = metadata.filters;

  return {
    ...metadata,
    filters: {
      ...metadata.filters,
      search: search ? search : undefined,
      driver: driver ? {id: driver.id} : undefined,
    }
  }
}


export const all = (metadata) =>  {
  return axios.get(API_URI, { params: { meta: customSerializeParams(metadata) }});
}
export const find = id => {
  return axios.get(`${API_URI}/${id}`);
}
export const destroy = id => {
  return axios.delete(`${API_URI}/${id}`);
}
export const create = (entity, files) => {
  return axios.post(API_URI, getFormData(normalize(entity), files), axiosFormDataConfigs );
}
export const update = (entity, files) => {
  return axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(normalize(entity), files), axiosFormDataConfigs);
}


