import { _reducer as busesReducer} from './slices/list'
import { _reducer as busesDeleteReducer} from './slices/delete'
import { _reducer as busesEditReducer} from './slices/edit'
import { _reducer as busesForSelectReducer} from './slices/listForSelect'

export const busesReducers = {
  ...busesReducer,
  ...busesDeleteReducer,
  ...busesEditReducer,
  ...busesForSelectReducer
}

export {
  fetchDispatcher as fetchBuses,
  useSelector as useBusesListState,
  actions as busesListActions
} from './slices/list'

export {
  useSelector as useBusesEditState,
  fetchDispatcher as fetchBusForEdit,
  resetDispatcher as resetBusForEdit,
  saveDispatcher as saveBus
} from './slices/edit'

export {
  useSelector as useBusesDeleteState,
  fetchDispatcher as fetchBusForDelete,
  deleteDispatcher as deleteBus
} from './slices/delete'

export {
  useSelector as useBusesForSelect,
  fetchDispatcher as fetchBusesForSelect,
  actions as busesForSelectActions
} from './slices/listForSelect'
