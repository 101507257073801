import React from "react";
import {
  AEDatePickerField,
  AEField, AEFileField
} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {DriverField} from "../../../Users/components/DriverField";
import {MODULES} from "../../../../../_ae/helpers/RoutingHelpers";
import {ParentField} from "../../../Users/components/ParentField";
import {TeamField} from "../../../Teams";
import {InputAdornment} from "@material-ui/core";

export const Form = ({formik: {values, handleSubmit}, btnRef}) => {

  return (
    <div className="form form-label-right">
      <div className={'row'}>
        <div className={'col-lg-4 form-group max-h-200px'}>
          <AEFileField
            name="fileName"
            label={'IMAGE'}
            preview
            previewPath={`/${MODULES.STUDENTS}`}
          />
        </div>
        <div className={'col-lg-8'}>
          <div className="row">
            <div className={'form-group col-md'}>
              <AEField
                name="firstName"
                label={'FIRST_NAME'}
              />
            </div>
            <div className={'form-group col-md'}>
              <AEField
                name="lastName"
                label={'LAST_NAME'}
              />
            </div>
            <div className={'form-group col-md'}>
              <AEField
                name="notificationArea"
                label={'NOTIFICATION_AREA'}
                type={'number'}
                InputProps={{
                  endAdornment: <InputAdornment position="end">m</InputAdornment>,
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className={'form-group col-md'}>
              <ParentField/>
            </div>
            <div className={'form-group col-md'}>
              <TeamField/>
            </div>
          </div>
        </div>
      </div>

      <button
        style={{ display: "none" }}
        ref={btnRef}
        onClick={handleSubmit}
      />
    </div>
  );
}

