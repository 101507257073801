/* eslint-disable no-restricted-imports */
import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Loader} from "../../../../../_ae/components/loader";
import {
  fetchVoyagesForBusHistory,
  useVoyagesForBusHistory,
  voyagesForBusHistoryActions
} from "../../../../../redux/voyages";
import {VoyageEntityCard} from "./VoyageEntityCard";
import {Pagination} from "../../../../../_metronic/_partials/controls";
import {busesListActions} from "../../../../../redux/buses";

export function History({match}) {
  const dispatch = useDispatch();
  const { metadata, isLoading, data } = useVoyagesForBusHistory()
  const {pagination, filters, sort} = metadata;
  const {setPage, setPerPage} = voyagesForBusHistoryActions;
  const id = match.params.id;


  useEffect(() => {

    let _mt = {...metadata};
    _mt.filters.bus = {id}
    console.log(_mt)

    dispatch(fetchVoyagesForBusHistory(metadata));
  }, [id, metadata, dispatch]);

  // const deleteEntity = () => {
  //   dispatch(deleteBus(id)).then(() => {
  //     history.goBack()
  //   });
  // };
  console.log(data)

  return (
    <>
      <Loader isLoading={isLoading} />

      <Pagination
        total={pagination.total}
        perPage={pagination.perPage}
        page={pagination.page}
        pages={pagination.pages}
        setPage={(page)=>{
          dispatch(setPage(page))
        }}
        setPerPage={(perPage)=>{
          dispatch(setPerPage(perPage))
        }}
        counts={[2, 4]}
        pager={false}
      />


      <div className={'row'}>
        {
          data/*.slice(0,1)*/.map(voyage=>(
            <div key={voyage.id} className={'col-md-12'}>
              <VoyageEntityCard
                size={'lg'}
                entity={voyage}
              />
            </div>
          ))
        }
      </div>



    </>

  );
}

