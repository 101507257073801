import React, {useEffect} from "react";
import {localField} from "../../../../_ae/helpers/UIHelper";
import {fetchRolesForSelect, rolesForSelectActions, useRolesForSelect} from "../../../../redux/roles";
import {AEAutocompleteField} from "../../../../_metronic/_partials/controls/forms/AEField";
import {useAuthState} from "../../../../redux/auth";
import {useDispatch} from "react-redux";

export const RoleField = () => {
  const dispatch = useDispatch();
  const rolesState = useRolesForSelect()
  const {user: authUser} = useAuthState()

  const HandleSearch = search => {
    dispatch(
      fetchRolesForSelect({
        ...rolesState.metadata,
        filters: {
          ...rolesState.metadata.filters,
          search
        }
      })
    )
  }

  return (
    <AEAutocompleteField
      name="role"
      label={'ROLE'}
      options={rolesState.data}
      getOptionLabel={role=>role[localField()]}
      onOpen={()=>{
        HandleSearch('')
      }}
      onInputChange={(event, search) => {
        HandleSearch(search)
      }}
      loading={rolesState.isLoading}
      getOptionDisabled={role => role.priority <= authUser.role.priority}
      withFeedbackLabel={false}
      customFeedbackLabel={false}
    />
  )
}