export const MODULES = {
  USERS: 'users',
  ROUTES: 'routes',
  ROLES: 'roles',
  LOGS: 'logs',
  SCHOOLS: 'schools',
  STUDENTS: 'students',
  TEAMS: 'teams',
  VOYAGES: 'voyages',
  BUSES: 'buses',

  PROFILE: 'profile',
  AUTH: 'auth'
}
export const VIEWS = {
  MENU: 'MENU',
  ACTION: 'ACTION',
  DIALOG: 'DIALOG'
}