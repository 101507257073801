import React, {useMemo} from "react";
import {Card, CardBody} from "../../../../../_metronic/_partials/controls";
import {EntityCartLabel, localField, SIZES} from "../../../../../_ae/helpers/UIHelper";
import {ICONS} from "../../../../../_ae/components/svg";
import {AEMoment} from "../../../../../_ae/components/moment";
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import {ExampleDirections} from './ExampleDirections';
import clsx from "clsx";
import {FormattedMessage} from "react-intl";

const {xs, sm, md, lg, xl, xxl} = SIZES;
const classes = {
  xs: {
    symbol: 30,
  },
  sm: {
    symbol: 50,
  },
  md: {
    symbol: 60,
  },
  lg: {
    symbol: 70,
  },
  xl: {
    symbol: 80,
  },
  xxl: {
    symbol: 80,
  },
}


export const VoyageEntityCard = ({
  entity,
  size= "md",
  className = '',
  avatar,
  editAction,
  deleteAction,
  historyAction,
}) => {

  const {sizeP, sizeClasses} = useMemo(()=>({
      sizeP: SIZES[size],
      sizeClasses: classes[size],
    }),
    [size])


  return (
    <Card className={`${className}`}>
      <CardBody className={`p-${sizeP}`}>
        <div className={`${clsx('d-flex justify-content-between align-items-center w-100 ', `pb-${sizeP}`)}`}>
          <div className={`${clsx('d-flex align-items-center')}`}>
            <div className='font-size-h4'>
              <AEMoment date={entity.createdAt} format={sizeP > md ? 'LLL' : 'LL'} />
            </div>
          </div>
          {/*<AERouteActions actions={actions} />*/}
        </div>

        {
          sizeP > xs &&
          <div className={`d-flex align-items-center`}>
            <div className={'w-100'}>
              {
                entity.bus &&
                <div>
                  <EntityCartLabel
                    id={'BUS'}
                    size={size}
                    iconPath={ICONS.BUSES}
                  >
                    {entity.bus.reg}
                  </EntityCartLabel>
                </div>
              }

              {
                entity.team &&
                <div>
                  <EntityCartLabel
                    id={'TEAM'}
                    size={size}
                    iconPath={ICONS.TEAMS}
                  >
                    {entity.team[localField()]}
                  </EntityCartLabel>
                </div>
              }

              <EntityCartLabel
                id={'CREATION_DATE'}
                iconPath={ICONS.CALENDAR}
                size={size}
              >
                <AEMoment date={entity.createdAt} format={sizeP > md ? 'LLL' : 'LL'} />
              </EntityCartLabel>

              <div className='font-size-h4 py-4'>
                <FormattedMessage id={'WAYPOINTS'} />
              </div>
              <ExampleDirections
                waypoints={entity.waypoints}
              />
            </div>

          </div>
        }

      </CardBody>
    </Card>
  )
}