import React, {useState} from "react";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/styles";
import {useIntl} from "react-intl";
import get from 'lodash/get';
import {InputAdornment} from "@material-ui/core";
import {AEButton} from "../../../../_ae/components/buttons";
import {AEIcon} from "../../../../_ae/components/svg";

const getFieldCSSClasses = (touched, errors) => {
  const classes = [];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

const useStyles = makeStyles({
  borderless: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: 0
      }
    }
    // border: 0,
  }
});

const PasswordInputAdornment = () => {
  const [visible, setVisible] = useState(false)

  const handleClickShowPassword = () => {
    setVisible(! visible);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <InputAdornment position="end">
      <AEButton
        icon
        variant={'link'}
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
      >
        <AEIcon
          path={`/General/${visible?'Visible':'Hidden'}.svg`}
          variant={visible ? 'primary' : ''}
        />
      </AEButton>
    </InputAdornment>
  )
}



export function AEInput({
  field,
  form,
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  validation = true,
  shrinkLabel = false,
  disabled = false,
  borderless = false,
  className = '', variant = "outlined",
  rows = 1,
  InputProps = {},
  ...props
}) {
  const { name, value, onChange, onBlur } = field;
  const { touched, errors, values, setFieldValue, setFieldTouched} = form;

  // console.log(props)

  // console.clear()
  // console.log(touched, errors, name, touched[name], errors[name], field)

  const {formatMessage} = useIntl()
  const classes = useStyles();
  label = formatMessage({id:label})

  let InputLabelProps =  { }
  if(shrinkLabel) {
    InputLabelProps.shrink = true;
  }

  switch (type) {
    case 'password':
      InputProps = {
        ...InputProps,
        // endAdornment: <PasswordInputAdornment />
      }
  }

  return (
    <>
      <TextField
        type={type}
        className={`${className} ${borderless ? classes.borderless : ''} -form-control -h-auto ${disabled ? 'bg-light' : ''}`+(validation && getFieldCSSClasses(touched[name], errors[name]))}
        variant={variant}
        value={value}
        InputLabelProps={InputLabelProps}
        size={"small"}
        fullWidth
        multiline={type === 'textarea'} rows={rows}
        disabled={disabled}
        label={label}
        onChange={event => {
          let v = event.target.value;
          if (type === "number"){
            v = parseInt(v)
          }
          setFieldValue(name, v)
        }}
        onBlur={event => {setFieldTouched(name,true)}}
        InputProps={InputProps}
        {...props} //warning endAdornment
      />
      {!disabled && withFeedbackLabel && (
        <FieldFeedbackLabel
          error={get(errors, name)}
          touched={get(touched, name)}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
