import React, {useEffect} from "react";
import {Pagination} from "../../../../../_metronic/_partials/controls";
import {useDispatch} from "react-redux";
import {EntityCard} from "../../components/EntityCard";
import {Filter} from "./Filter";
import {useMetadata} from "../../../../../_ae/AEPagination";
import {fetchRoles, useRolesList} from "../../../../../redux/roles";
import {Loader} from "../../../../../_ae/components/loader";

export const List = () => {
  const dispatch = useDispatch();
  const {data, isLoading, metadata} = useRolesList()

  const metadataState = useMetadata(metadata)
  const { page, perPage, sortAsc, filters, sortField, select } = metadataState;

  /* Hooks */
  useEffect(() => {
    dispatch(fetchRoles(metadataState.serialize()));
  }, [dispatch, page, perPage, filters.search, filters.routes, select, sortField, sortAsc]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <Filter {...metadataState}/>
      <div className={'row'}>
        {
          data.map(r=>(
            <div key={r.id} className={'col-12'}>
              <EntityCard
                key={r.id}
                entity={r}
                size={'md'}
                className={'card-stretch'}
                editAction
                deleteAction
              />
            </div>
          ))
        }
      </div>
      <Pagination
        {...metadataState} counts={[12, 24, 48, 96]}
      />
    </>
  );
}
