import React from "react";
import {
  AEDatePickerField,
  AEField
} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {DriverField} from "../../../Users/components/DriverField";

export const Form = ({formik: {values, handleSubmit}, btnRef}) => {

  return (
    <div className="form form-label-right">
      <div className={'row'}>
        <div className={'col-lg-12'}>
          <div className="row">
            <div className={'form-group col-md'}>
              <AEField
                name="reg"
                label={'REG'}
              />
            </div>
            <div className={'form-group col-md'}>
              <AEDatePickerField
                name="regDate"
                label={'REG_DATE'}
                type={'date'}
              />
            </div>
          </div>
          <div className="row">
            <div className={'form-group col-md'}>
              <DriverField/>
            </div>
          </div>
        </div>
      </div>

      <button
        style={{ display: "none" }}
        ref={btnRef}
        onClick={handleSubmit}
      />
    </div>
  );
}

