import React, { useState } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsService,
  DirectionsRenderer,
} from '@react-google-maps/api';

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const key = 'AIzaSyCdjO5TYYGA3Z3YAX3dNdovRh_HctBffk0'

export const ExampleDirections = ({waypoints}) => {
  const [teste2, setTeste2] = useState([]);

  const directionsCallback = (result) => {
    console.log(result);
    setTeste2(result);
  }

  const originWaypoint = waypoints[0]
  const destinationWaypoint = waypoints[waypoints.length-1]
  const origin = `${originWaypoint.lat}, ${originWaypoint.lng}`
  const destination = `${destinationWaypoint.lat}, ${destinationWaypoint.lng}`

  return (
    <LoadScript googleMapsApiKey={key}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={8}
        center={waypoints[0]}
        clickableIcons={false}
      >
        {/*{waypoints.map((waypoint) => (*/}
        {/*  <Marker*/}
        {/*    key={waypoint.id}*/}
        {/*    position={{ lat: waypoint.lat, lng: waypoint.lng }}*/}
        {/*  />*/}
        {/*))}*/}
        <DirectionsService
          options={{
            destination,
            origin,
            travelMode: 'DRIVING',
            waypoints: waypoints.map(waypoint=>({
              location: waypoint
            }))
          }}
          callback={directionsCallback}
        />
        <DirectionsRenderer
          options={{
            directions: teste2,
          }}
        />
      </GoogleMap>
    </LoadScript>
  );
};