import React from "react";
import {AEAutocompleteField} from "../../../../_metronic/_partials/controls/forms/AEField";
import {useDispatch} from "react-redux";
import {fetchParentsForSelect, useParentsForSelect} from "../../../../redux/users";
import {getFullName} from "./FullName";
import {CONFIG} from "../../../../_ae/config";

export const ParentField = ({...props}) => {
  const dispatch = useDispatch();
  const {data, metadata, isLoading} = useParentsForSelect()

  const HandleSearch = search => {
    dispatch(
      fetchParentsForSelect({
        ...metadata,
        filters: {
          ...metadata.filters,
          search
        }
      })
    )
  }

  return (
    <AEAutocompleteField
      name='parent'
      label={CONFIG.ROLES.PARENT}
      options={data}
      getOptionLabel={getFullName}
      onOpen={()=>{
        HandleSearch('')
      }}
      onInputChange={(event, search) => {
        HandleSearch(search)
      }}
      loading={isLoading}
      withFeedbackLabel={false}
      customFeedbackLabel={false}
      {...props}
    />
  )
}