export const CONFIG = {
  ROLES: {
    SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
    SCHOOL_OWNER: 'ROLE_SCHOOL_OWNER',
    DRIVER: 'ROLE_DRIVER',
    PARENT: 'ROLE_PARENT',
  },
  auth: {
    login: {
      google: false
    },
    register: {
      enabled: false
    },
  }
}

//fixme: duplicated
export const ROLES = CONFIG.ROLES;


