import React from "react";
import {localField} from "../../../../_ae/helpers/UIHelper";
import {fetchBusesForSelect, useBusesForSelect} from "../../../../redux/buses";
import {AEAutocompleteField} from "../../../../_metronic/_partials/controls/forms/AEField";
import {useDispatch} from "react-redux";

export const Field = ({...props}) => {
  const dispatch = useDispatch();
  const {data, metadata, isLoading} = useBusesForSelect()

  const HandleSearch = search => {
    dispatch(
      fetchBusesForSelect({
        ...metadata,
        filters: {
          ...metadata.filters,
          search
        }
      })
    )
  }

  return (
    <AEAutocompleteField
      name='bus'
      label={'BUS'}
      options={data}
      getOptionLabel={role=>role[localField()]}
      onOpen={()=>{
        HandleSearch('')
      }}
      onInputChange={(event, search) => {
        HandleSearch(search)
      }}
      loading={isLoading}
      withFeedbackLabel={false}
      customFeedbackLabel={false}
      {...props}
    />
  )
}