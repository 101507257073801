import React from "react";
import {
  AEAutocompleteField,
  AEField,
  AEFileField
} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {RoleField} from "../../../Roles/components/RoleField";
import {SchoolField} from "../../../Schools";
import {AERole} from "../../../../../_ae/components/AERole";
import {CONFIG} from "../../../../../_ae/config";
import {FieldArray} from "formik";
import {FormattedMessage, useIntl} from "react-intl";
import {AEButton} from "../../../../../_ae/components/buttons";
import {defaultAttribute} from "../../../../../redux/schools/slices/edit";
import {Card, CardBody} from "../../../../../_metronic/_partials/controls";
import {getAttributeKeys} from "../../components/Attribute";
import {AEIcon, ICONS} from "../../../../../_ae/components/svg";

export const Form = ({formik: {values, handleSubmit}, btnRef}) => {
  const {formatMessage} = useIntl()

  return (
    <div className="form form-label-right">
      <div className={'row gutter-b'}>
        <div className={'form-group col-lg-4'}>
          <AEFileField
            name="fileName"
            label={'IMAGE'}
            preview
            previewPath={'/users'}
          />
        </div>
        <div className={'col-lg-8'}>
          <div className="row">
            <div className={'form-group col-md'}>
              <AEField
                name="firstName"
                label={'FIRST_NAME'}
              />
            </div>
            <div className={'form-group col-md'}>
              <AEField
                name="lastName"
                label={'LAST_NAME'}
              />
            </div>
          </div>
          <div className="row">
            <div className={'form-group col-md'}>
              <AEField
                name="username"
                label={'USERNAME'}
              />
            </div>
            <div className={'form-group col-md'}>
              <AEField
                name="email"
                label={'EMAIL'}
              />
            </div>

          </div>
          {
            ! values.id &&
            <div className={'row'}>
              <div className={'form-group col-md'}>
                <AEField
                  name="password"
                  label={'PASSWORD'}
                  type={'password'}
                />
              </div>
              <div className={'form-group col-md'}>
                <AEField
                  name="_confirm"
                  label={'PASSWORD_CONFIRM'}
                  type={'password'}
                />
              </div>
            </div>
          }
          <div className={'row'}>
            <div className="form-group col-md">
              <RoleField />
            </div>
            <AERole roles={[CONFIG.ROLES.SUPER_ADMIN]}>
              <div className="form-group col-md">
                <SchoolField />
              </div>
            </AERole>

          </div>

        </div>
      </div>

      <div>
        <FieldArray
          name="attributes"
          render={(arrayHelpers) => (
            <div>
              <div className="d-flex justify-content-between gutter-b">
                <div className="font-weight-bold font-size-h3 pl-4">
                  <FormattedMessage id={'ATTRIBUTES'} />
                </div>
                <div>
                  <AEButton
                    size={'sm'}
                    onClick={()=>{
                      arrayHelpers.push(defaultAttribute)
                    }}
                  >
                    <FormattedMessage id={'ADD_ATTRIBUTE'} />
                  </AEButton>
                </div>
              </div>
              <div>
                {
                  values.attributes.map((attribute, i)=>(
                    <Card key={i} className="card-border">
                      <CardBody className="position-relative">
                        <div className={'row'}>
                          <div className={'-form-group col-md-6'}>
                            <AEAutocompleteField
                              name={`attributes.${i}.attKey`}
                              label={'TYPE'}
                              options={getAttributeKeys()}
                              getOptionLabel={key=>formatMessage({id: key ||'aaa'})}
                            />
                          </div>
                          <div className={'-form-group col-md-5'}>
                            <AEField
                              name={`attributes.${i}.attValue`}
                              label={'VALUE'}
                            />
                          </div>
                        </div>
                        <AEButton
                          variant={'danger'}
                          icon
                          shadow
                          size={'sm'}
                          className='position-absolute top-0 right-0'
                          style={{transform: 'translate(50%, 50%)'}}
                          onClick={()=>{
                            arrayHelpers.remove(i)
                          }}
                        >
                          <AEIcon
                            path={ICONS.DELETE}
                          />
                        </AEButton>
                      </CardBody>
                    </Card>
                  ))
                }
              </div>

            </div>
          )}
        />
      </div>

      <button
        style={{ display: "none" }}
        ref={btnRef}
        onClick={handleSubmit}
      />
    </div>
  );
}

