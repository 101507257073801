import React from "react";
import {localField} from "../../../../_ae/helpers/UIHelper";
import {fetchSchoolsForSelect, useSchoolsForSelect} from "../../../../redux/schools";
import {AEAutocompleteField} from "../../../../_metronic/_partials/controls/forms/AEField";
import {useDispatch} from "react-redux";

export const Field = ({useTenantKey, setFieldValue, values, ...props}) => {
  const dispatch = useDispatch();
  const {data, metadata, isLoading} = useSchoolsForSelect()

  const HandleSearch = search => {
    dispatch(
      fetchSchoolsForSelect({
        ...metadata,
        filters: {
          ...metadata.filters,
          search
        }
      })
    )
  }

  /*
  *
  * value={values.tenantKey}
    getOptionLabel={k=>o[localField()]}
    onChange={(_,v,reason) => {
      setFieldValue('tenantKey', reason === "clear" ? null : v.tenantKey);
    }}
  * */

  props.getOptionLabel = o=>o[localField()]

  // if (useTenantKey) {
  //   props.getOptionLabel = tenantKey=>{
  //     const tenant = data.find(school=>school.tenantKey === tenantKey)
  //
  //     return tenant ? tenant[localField()] : ''
  //   }
  //   props.value = values.tenantKey
  //   props.onChange = (_,v,reason) => {
  //     setFieldValue('tenantKey', reason === "clear" ? null : v.tenantKey);
  //   }
  // }





  return (
    <AEAutocompleteField
      name='school'
      label={'SCHOOL'}
      options={data}
      getOptionLabel={o=>o[localField()]}
      onOpen={()=>{
        HandleSearch('')
      }}
      onInputChange={(event, search) => {
        HandleSearch(search)
      }}
      loading={isLoading}
      withFeedbackLabel={false}
      customFeedbackLabel={false}
      {...props}
    />
  )
}