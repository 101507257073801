import axios from "axios";
import {axiosFormDataConfigs, getFormData, serializeParams} from "../../_ae/helpers/AxiosHelpers";
import {toAbsoluteApiUrl} from "../../_metronic/_helpers";
import set from "lodash/set";

export const API_URI = toAbsoluteApiUrl("/voyages");

export const normalize = (entity) => {
  return entity
}

export const all = (metadata) =>  {
  return axios.get(API_URI, { params: { meta: serializeParams(metadata) }});
}
export const find = id => {
  return axios.get(`${API_URI}/${id}`);
}
export const destroy = id => {
  return axios.delete(`${API_URI}/${id}`);
}
export const create = (entity, files) => {
  return axios.post(API_URI, getFormData(normalize(entity), files), axiosFormDataConfigs );
}
export const update = (entity, files) => {
  return axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(normalize(entity), files), axiosFormDataConfigs);
}


