import { _reducer as schoolsReducer} from './slices/list'
import { _reducer as schoolsDeleteReducer} from './slices/delete'
import { _reducer as schoolsEditReducer} from './slices/edit'
import { _reducer as schoolsForSelectReducer} from './slices/listForSelect'

export const schoolsReducers = {
  ...schoolsReducer,
  ...schoolsDeleteReducer,
  ...schoolsEditReducer,
  ...schoolsForSelectReducer
}

export {
  fetchDispatcher as fetchSchools,
  useSelector as useSchoolsListState,
  actions as schoolsListActions
} from './slices/list'

export {
  useSelector as useSchoolsEditState,
  fetchDispatcher as fetchSchoolForEdit,
  resetDispatcher as resetSchoolForEdit,
  saveDispatcher as saveSchool
} from './slices/edit'

export {
  useSelector as useSchoolsDeleteState,
  fetchDispatcher as fetchSchoolForDelete,
  deleteDispatcher as deleteSchool
} from './slices/delete'

export {
  useSelector as useSchoolsForSelect,
  fetchDispatcher as fetchSchoolsForSelect,
  actions as schoolsForSelectActions
} from './slices/listForSelect'
