import React from "react";
import PropTypes from 'prop-types';
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_metronic/_helpers";


/* TODO
*   list all icons
*   icon classes
* */

export const ICONS = {
  LIST:'/Layout/Layout-grid.svg',
  PLUS:'/Code/Plus.svg',
  EDIT:'/Design/Edit.svg',
  SAVE:'/Code/Done-circle.svg',
  DELETE:'/Code/Error-circle.svg',
  MAP_MARKER:'/Map/Marker1.svg',
  AREA:'/Home/Globe.svg',
  EMAIL: '/Communication/Mail.svg',
  HEARTH: '/General/Heart.svg',
  DOTS:'/General/Other2.svg',
  DETAIL:'/General/Other2.svg',
  HOME:'/Home/Home.svg',
  GENDER:'/Clothes/Tie.svg',
  MALE:'/Clothes/Tie.svg',
  FEMALE:'/Clothes/Dress.svg',
  CALENDAR:'/Design/Select.svg',
  KEY:'/Design/Select.svg',
  MINUS: '/Navigation/Minus.svg',
  NOTIFICATION: '/General/Notifications1.svg',

  USERS:'/Communication/Shield-user.svg',
  USERS_DELETE:'/Communication/Delete-user.svg',
  USERS_NEW: '/Communication/Add-user.svg',
  ROLES: '/General/Shield-protected.svg',
  SCHOOLS: '/Home/Building.svg',
  STUDENTS: '/General/User.svg',
  TEAMS: '/Communication/Group.svg',
  VOYAGES: '/Communication/Flag.svg',
  BUSES: '/Navigation/Route.svg',
}


export const AEIcon = ({ path, variant, size , className, prefix = '/icons', ...props}) =>{
  let classes = [className, "svg-icon", "menu-icon"];
  if(variant) classes.push(`svg-icon-${variant}`)
  if(size) classes.push(`svg-icon-${size}`)
  return (
    <span className={classes.join(' ')} {...props}>
      <SVG src={toAbsoluteUrl(`/media/svg/${prefix}/${path}`)} style={{pointerEvents: 'none' }}/>
    </span>
  )
}
AEIcon.propTypes = {
  // icon: PropTypes.oneOf(["General/Search.svg"]),//todo
  path: PropTypes.string,
  variant: PropTypes.string
};