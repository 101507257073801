import React, {useMemo} from "react";
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import clsx from "clsx";
import {EntityCartLabel, localField, SIZES, toAbsoluteUrl} from "../../../../_ae/helpers/UIHelper";
import {useAuthState} from "../../../../redux/auth";
import {AERouteActions} from "../../../../_ae/components/AERouteActions";
import {ICONS} from "../../../../_ae/components/svg";
import {toEntityFileNameUrl} from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import {MODULES} from "../../../../_ae/helpers/RoutingHelpers";

const {xs, sm, md, lg, xl, xxl} = SIZES;
const classes = {
  xs: {
    symbol: 30,
  },
  sm: {
    symbol: 50,
  },
  md: {
    symbol: 60,
  },
  lg: {
    symbol: 70,
  },
  xl: {
    symbol: 80,
  },
  xxl: {
    symbol: 80,
  },
}


export const EntityCard = ({
  entity,
  size= "md",
  className = '',
  avatar,
  editAction,
  deleteAction,
}) => {

  const { school:authSchool } = useAuthState()

  const {sizeP, sizeClasses} = useMemo(()=>({
      sizeP: SIZES[size],
      sizeClasses: classes[size],
    }),
    [size])


  const actions = useMemo(()=>{

    return [
      {id: 'SCHOOLS.EDIT', params: {id: entity.id}, hidden: ! editAction},
      {id: 'SCHOOLS.DELETE', params: {id: entity.id}, hidden: ! deleteAction},
    ].filter(action=>! action.hidden);
  }, [entity, authSchool, editAction, deleteAction])

  const Image = useMemo(()=>{
    const url = entity.fileName ?
      toEntityFileNameUrl(entity, MODULES.SCHOOLS) :
      toAbsoluteUrl("/media/svg/avatars/004-boy-1.svg")

    return () => avatar && (
      <div className="symbol symbol-40 symbol-2by3 mr-3">
        <div
          className="symbol-label"
          style={{
            backgroundImage: `url('${url}')`
          }}
        />
      </div>
    )
  }, [entity, sizeP])


  return (
    <Card className={`${className}`}>
      <CardBody className={`p-${sizeP}`}>
        <div className={`${clsx('d-flex justify-content-between align-items-center w-100 ', `pb-${sizeP}`)}`}>
          <div className={`${clsx('d-flex align-items-center')}`}>
            {
              sizeP === xs && <Image />
            }
            <span className={`font-size-h${xxl-sizeP+3} font-weight-bold`}>
              {entity[localField()]}
            </span>

          </div>
          <AERouteActions actions={actions} />
        </div>

        {
          sizeP > xs &&
          <div className={`d-flex align-items-center`}>
            <Image />
            <div>
              <div>
                <EntityCartLabel
                  id={'EMAIL'}
                  iconPath={ICONS.EMAIL}
                  size={size}
                >
                  {entity.email}
                </EntityCartLabel>
              </div>
              {
                entity.address &&
                <div>
                  <EntityCartLabel
                    id={'ADDRESS'}
                    iconPath={ICONS.MAP_MARKER}
                    size={size}
                  >
                    {entity.address}
                  </EntityCartLabel>
                </div>
              }

              {
                entity.attributes.map(attribute=>{
                  return (
                    <div key={attribute.id}>
                      <EntityCartLabel
                        id={attribute.attKey}
                        size={size}
                      >
                        {attribute.attValue}
                      </EntityCartLabel>
                    </div>
                  )
                })
              }
            </div>
          </div>
        }

      </CardBody>
    </Card>
  )
}