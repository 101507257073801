import { _reducer as teamsReducer} from './slices/list'
import { _reducer as teamsDeleteReducer} from './slices/delete'
import { _reducer as teamsEditReducer} from './slices/edit'
import { _reducer as teamsForSelectReducer} from './slices/listForSelect'

export const teamsReducers = {
  ...teamsReducer,
  ...teamsDeleteReducer,
  ...teamsEditReducer,
  ...teamsForSelectReducer
}

export {
  fetchDispatcher as fetchTeams,
  useSelector as useTeamsListState,
  actions as teamsListActions
} from './slices/list'

export {
  useSelector as useTeamsEditState,
  fetchDispatcher as fetchTeamForEdit,
  resetDispatcher as resetTeamForEdit,
  saveDispatcher as saveTeam
} from './slices/edit'

export {
  useSelector as useTeamsDeleteState,
  fetchDispatcher as fetchTeamForDelete,
  deleteDispatcher as deleteTeam
} from './slices/delete'

export {
  useSelector as useTeamsForSelect,
  fetchDispatcher as fetchTeamsForSelect,
  actions as teamsForSelectActions
} from './slices/listForSelect'
