import React, {useMemo} from "react";
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import clsx from "clsx";
import {localField, SIZES} from "../../../../_ae/helpers/UIHelper";
import {useAuthState} from "../../../../redux/auth";
import {AERouteActions} from "../../../../_ae/components/AERouteActions";

const {xs, sm, md, lg, xl, xxl} = SIZES;
const classes = {
  xs: {
    symbol: 30,
  },
  sm: {
    symbol: 50,
  },
  md: {
    symbol: 60,
  },
  lg: {
    symbol: 70,
  },
  xl: {
    symbol: 80,
  },
  xxl: {
    symbol: 80,
  },
}


export const EntityCard = ({
  entity,
  size= "md",
  className = '',
  avatar,
  editAction,
  deleteAction,
}) => {

  const { user:authUser } = useAuthState()

  const {sizeP, sizeClasses} = useMemo(()=>({
      sizeP: SIZES[size],
      sizeClasses: classes[size],
    }),
    [size])


  const actions = useMemo(()=>{

    return [
      {id: 'TEAMS.EDIT', params: {id: entity.id}, hidden: ! editAction},
      {id: 'TEAMS.DELETE', params: {id: entity.id}, hidden: ! deleteAction},
    ].filter(action=>! action.hidden);
  }, [entity, authUser, editAction, deleteAction])

  // const Image = useMemo(()=>{
  //   return () => avatar && (
  //     <>
  //       {
  //         entity.fileName ?
  //           <img
  //             src={toEntityFileNameUrl(entity, MODULES.SCHOOLS)}
  //             alt={` `}
  //             className={'h-100'}
  //           />
  //           :
  //           <span className="symbol-label">
  //           <SVG
  //             className="h-75 align-self-end"
  //             src={toAbsoluteUrl("/media/svg/avatars/004-boy-1.svg")}
  //           />
  //         </span>
  //       }
  //     </>
  //   )
  // }, [entity, sizeP])


  return (
    <Card className={`${className}`}>
      <CardBody className={`p-${sizeP}`}>
        <div className='d-flex justify-content-between align-items-center w-100'>
          <div className={`${clsx('d-flex align-items-center')}`}>
            {/*{*/}
            {/*  sizeP === xs && <Image />*/}
            {/*}*/}
            <span className={`font-size-h${xxl-sizeP+3} font-weight-bold`}>
              {entity[localField()]}
            </span>

          </div>
          <AERouteActions actions={actions} />
        </div>

        {
          // sizeP > xs &&
          // <div className={`d-flex align-items-center`}>
          //   <Image />
          //   <div>
          //     <EntityCartLabel
          //       id={'CREATION_DATE'}
          //       iconPath={ICONS.CALENDAR}
          //       size={size}
          //     >
          //       <AEMoment date={entity.createdAt} format={sizeP > md ? 'LLL' : 'LL'} />
          //     </EntityCartLabel>
          //   </div>
          // </div>
        }

      </CardBody>
    </Card>
  )
}