import React, {useEffect, useState} from "react";
import {FormikProvider, useFormik} from "formik";
import {AEButton} from "../../../../../_ae/components/buttons";
import {Card, CardBody} from "../../../../../_metronic/_partials/controls";
import {AEIcon} from "../../../../../_ae/components/svg";
import {ButtonGroup, Dropdown} from "react-bootstrap";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {AEAutocompleteField, AEField} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {useMetadata} from "../../../../../_ae/AEPagination";
import {localField} from "../../../../../_ae/helpers/UIHelper";
import {
  fetchRoutesForRolesFilter,
  useRoutesForRolesFilterState
} from "../../../../../redux/routes";
import {createFilterOptions} from "@material-ui/lab";

const sorts = [
  {prop: localField(), label: "NAME"},
  {prop: "priority", label: "PRIORITY"}
]

export const Filter = ({ setFilters, setSort, sort, setSortAsc }) => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl()
  const [advancedSearch, setAdvancedSearch] = useState(false)

  const routesState = useRoutesForRolesFilterState()
  const routesMetaHook = useMetadata(routesState.metadata)

  const formik = useFormik({
    initialValues: {
      search: "",
      routes: undefined,
    },
    onSubmit:({routes, ...values}) => {
      if(routes) {
        values.routes = {id: routes.id};
      }
      setFilters(values)
    }
  })


  useEffect(()=>{
    dispatch(fetchRoutesForRolesFilter(routesMetaHook.serialize()))
  }, [dispatch])

  useEffect(()=>{
    formik.handleSubmit()
  }, [formik.values])

  const fieldProps = {
    withFeedbackLabel: false,
    validation: false,
  }

  const filterOptions = createFilterOptions({
    stringify: option => formatMessage({id: option.routeKey}),
  });

  return (
    <Card>
      <CardBody>
        <FormikProvider value={formik}>
          <div className="d-flex align-items-center">
            <div className={`position-relative w-md-400px me-md-2`}>
              <AEField
                name="search"
                label={'SEARCH'}
                {...fieldProps}
              />
            </div>
            <div className={'d-flex align-items-center'}>
              <Dropdown as={ButtonGroup} className={"pl-2"} >
                <AEButton variant={"light"} fontWeight={"bolder"} onClick={()=>{
                  setSortAsc(!sort.asc)
                }}>
                  <AEIcon path={`/Navigation/${sort.asc ? 'UP':'DOWN'}-2.svg`} variant={"primary"}/>
                  {
                    formatMessage({id: sorts.findIndex(o=>o.prop === sort.field) !== -1 ? sorts.find(o=>o.prop === sort.field).label:"SORT"})
                  }
                </AEButton>
                <Dropdown.Toggle split variant="light"/>
                <Dropdown.Menu className={"py-5"}>
                  {
                    sorts.map(o=>(
                      <Dropdown.Item key={o.prop} onClick={() => {setSort(o.prop, o.asc)}}>
                        {formatMessage({id:o.label},{asc:o.asc})}
                      </Dropdown.Item>
                    ))
                  }
                </Dropdown.Menu>
              </Dropdown>

              <div className={''}>
                <AEButton
                  variant={'link'}
                  fontWeight={'bold'}
                  onClick={()=>{
                    setAdvancedSearch(!advancedSearch)
                  }}
                >
                  {formatMessage({id: advancedSearch ? 'HIDE_ADVANCED_SEARCH' : 'ADVANCED_SEARCH'})}
                </AEButton>
              </div>
            </div>


          </div>
          {
            advancedSearch &&
            <>
              <div className="separator separator-dashed mt-9 mb-6" />
              <div className={'row'}>
                <div className={'col-lg form-group'}>
                  <AEAutocompleteField
                    name="routes"
                    label={'ROUTE'}
                    options={routesState.data}
                    getOptionLabel={o=>formatMessage({id: o.routeKey})}
                    filterOptions={filterOptions}
                    {...fieldProps}
                  />
                </div>
              </div>
            </>
          }
        </FormikProvider>
      </CardBody>
    </Card>
  )
}