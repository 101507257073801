import { _reducer as studentsReducer} from './slices/list'
import { _reducer as studentsDeleteReducer} from './slices/delete'
import { _reducer as studentsEditReducer} from './slices/edit'
import { _reducer as studentsForSelectReducer} from './slices/listForSelect'

export const studentsReducers = {
  ...studentsReducer,
  ...studentsDeleteReducer,
  ...studentsEditReducer,
  ...studentsForSelectReducer
}

export {
  fetchDispatcher as fetchStudents,
  useSelector as useStudentsListState,
  actions as studentsListActions
} from './slices/list'

export {
  useSelector as useStudentsEditState,
  fetchDispatcher as fetchStudentForEdit,
  resetDispatcher as resetStudentForEdit,
  saveDispatcher as saveStudent
} from './slices/edit'

export {
  useSelector as useStudentsDeleteState,
  fetchDispatcher as fetchStudentForDelete,
  deleteDispatcher as deleteStudent
} from './slices/delete'

export {
  useSelector as useStudentsForSelect,
  fetchDispatcher as fetchStudentsForSelect,
  actions as studentsForSelectActions
} from './slices/listForSelect'
