import React, {useMemo} from "react";
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import clsx from "clsx";
import {EntityCartLabel, SIZES, toAbsoluteUrl} from "../../../../_ae/helpers/UIHelper";
import {useAuthState} from "../../../../redux/auth";
import {AERouteActions} from "../../../../_ae/components/AERouteActions";
import {ICONS} from "../../../../_ae/components/svg";
import {toEntityFileNameUrl} from "../../../../_metronic/_helpers";
import {MODULES} from "../../../../_ae/helpers/RoutingHelpers";
import {CONFIG} from "../../../../_ae/config";
import {FullName} from "../../Users/components/FullName";
import {AEMoment} from "../../../../_ae/components/moment";

const {xs, sm, md, lg, xl, xxl} = SIZES;
const classes = {
  xs: {
    symbol: 30,
  },
  sm: {
    symbol: 50,
  },
  md: {
    symbol: 60,
  },
  lg: {
    symbol: 70,
  },
  xl: {
    symbol: 80,
  },
  xxl: {
    symbol: 80,
  },
}


export const EntityCard = ({
  entity,
  size= "md",
  className = '',
  avatar,
  editAction,
  deleteAction,
  historyAction,
}) => {

  const { bus:authBus } = useAuthState()

  const {sizeP, sizeClasses} = useMemo(()=>({
      sizeP: SIZES[size],
      sizeClasses: classes[size],
    }),
    [size])


  const actions = useMemo(()=>{

    return [
      {id: 'BUSES.EDIT', params: {id: entity.id}, hidden: ! editAction},
      {id: 'BUSES.DELETE', params: {id: entity.id}, hidden: ! deleteAction},
      {id: 'BUSES.HISTORY', params: {id: entity.id}, hidden: ! historyAction},
    ].filter(action=>! action.hidden);
  }, [entity, authBus, editAction, deleteAction, historyAction])

  const Image = useMemo(()=>{
    const url = entity.fileName ?
      toEntityFileNameUrl(entity, MODULES.BUSES) :
      toAbsoluteUrl("/media/svg/avatars/004-boy-1.svg")

    return () => avatar ? (
      <div className="symbol symbol-40 symbol-2by3 mr-3">
        <div
          className="symbol-label"
          style={{
            backgroundImage: `url('${url}')`
          }}
        />
      </div>
    ) : ''
  }, [entity, sizeP])


  return (
    <Card className={`${className}`}>
      <CardBody className={`p-${sizeP}`}>
        <div className={`${clsx('d-flex justify-content-between align-items-center w-100 ', `pb-${sizeP}`)}`}>
          <div className={`${clsx('d-flex align-items-center')}`}>
            {
              sizeP === xs && <Image />
            }
            <span className={`font-size-h${xxl-sizeP+3} font-weight-bold`}>
              {entity.reg}
            </span>
          </div>
          <AERouteActions actions={actions} />
        </div>

        {
          sizeP > xs &&
          <div className={`d-flex align-items-center`}>
            <Image />
            <div>
              {
                entity.driver &&
                <div>
                  <EntityCartLabel
                    id={CONFIG.ROLES.DRIVER}
                    size={size}
                  >
                    <FullName user={entity.driver}/>
                  </EntityCartLabel>
                </div>
              }
              {
                entity.regDate &&
                <div>
                  <EntityCartLabel
                    id={'REG_DATE'}
                    size={size}
                    iconPath={ICONS.CALENDAR}
                  >
                    <AEMoment date={entity.regDate} />
                  </EntityCartLabel>
                </div>
              }
            </div>
          </div>
        }

      </CardBody>
    </Card>
  )
}